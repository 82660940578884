<template>
    <div class="nav-left-panel">
        <ts-search :param="{}"></ts-search>

        <div class="nav-left-panel__list">
            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">药品穿透</p>
                <p class="nav-left-panel__desc" v-for="item in drugList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">医学文献</p>
                <p class="nav-left-panel__desc" v-for="item in docList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">销售数据</p>
                <p class="nav-left-panel__desc" v-for="item in saleList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">报告大厅</p>
                <p class="nav-left-panel__desc" @click="goReport">
                    报告列表
                    <img class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visitId: new Date().getTime(),
                pageId: 'navAnalysis',
                pageName: '导航-助手分析',

                searchTxt: '',

                drugList: [],
                docList: [],
                saleList: [],
            }
        },
        created() {

            this.$help.getUrlIndex(14, 20, undefined, this.visitId)
                .then(res => {
                    this.drugList = res.data.records
                })

            this.$help.getUrlIndex(15, 20, undefined, this.visitId)
                .then(res => {
                    this.docList = res.data.records
                })

            this.$help.getUrlIndex(16, 20, undefined, this.visitId)
                .then(res => {
                    this.saleList = res.data.records
                })
            this.$help.socket.send(this)
        },
        methods: {
            querySearch(queryString, cb) {
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword: queryString}).then(res => {
                    cb(res.data);
                });
            },
            search() {
                if(this.searchTxt) {
                    this.$router.push({
                        path: `/drug/${this.$help.encryption(this.searchTxt)}`,
                    })
                }
            },
            goReport() {
                const { href } = this.$router.resolve({
                    path: '/report',
                });
                window.open(href, "_blank");
            },
        }
    }
</script>